import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import SEO from "../../components/seo"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Img from "gatsby-image"
import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import ReactTimeAgo from 'react-time-ago'
import JavascriptTimeAgo from 'javascript-time-ago'
import it from 'javascript-time-ago/locale/it'
import Button from "react-bootstrap/Button"
import LayoutML from "../../components/layout-ml"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
      query {
          notices: allDirectusPublicNotice(sort: {order: DESC, fields: date}, filter: {type: {eq: "information_technology"}}) {
              nodes {
                  name
                  date
                  description
                  file {
                      localFile {
                          publicURL
                      }
                  }
              }
          }
          image: file(relativePath: { eq: "public-notices.jpg" }) {
              childImageSharp {
                  fluid {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }
  `)

  JavascriptTimeAgo.locale(it)

  const fixDateForAllBrowsers = dateString => dateString.replace(/-/g, '/');

  const seo = {
    title: "Bandi settore information technology",
    description: "Scopri i nuovi bandi per il settore information technology",
  }

  return (
    <LayoutML>
      <SEO title={seo.title} description={seo.description} lang={"it"} url={"/public-notices/information-technology"} />
      <Container fluid>
        <Row className={"site-content"}>
          <Col md={12} lg={6} xl={5} className={"offset-lg-1"}>
            <h1>Bandi settore information technology</h1>
            { data.notices.nodes.map((notice, key) => (
              <Card key={key} className={"my-3 notice"}>
                <Card.Body>
                  <Card.Title className={"d-flex"}>
                    <span>{ notice.name }</span>
                    { notice.file.localFile ? (
                      <Button href={notice.file.localFile.publicURL} target={"_blank"} size={"sm"} variant={"outline-primary"} className={"float-right"}>Scarica</Button>
                    ) : (<></>)}
                  </Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">Rilasciato <ReactTimeAgo date={ fixDateForAllBrowsers(notice.date) } locale={"it"}/></Card.Subtitle>
                  <Card.Text className={"my-0"}>{ notice.description }</Card.Text>
                </Card.Body>
              </Card>
            ))}
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={5} className={"offset-lg-1 p-0"}>
            <Img fluid={data.image.childImageSharp.fluid} style={{ width: `100%` }} />
          </Col>
        </Row>
      </Container>
    </LayoutML>
  )
}

export default IndexPage
